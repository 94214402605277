import {
  Button,
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Input,
  Paper,
  Slider,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { DateTimePicker } from "../../../components/DateInput";
import { DateTime, Duration } from "luxon";
import axios from "axios";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import Alert from "@mui/material/Alert";
import LoadingButton from "@mui/lab/LoadingButton";
import { FormItem } from "../../../components/FormItem";
import { ControlledTextbox } from "../../../components/FormComponents";
import Box from "@mui/material/Box";
import { FetchedPlayback } from "./ReplayList";
import { ArrowBack } from "@mui/icons-material";
import { useAppSelector } from "../../hooks/reduxHooks";

export function CreateReplay(props: { doClose?: Function }) {
  const defaultMetadata = useMemo(
    () => ({
      name: "",
      createdBy: "",
      createdAt: DateTime.now(),
      notes: "",
      duration: 1000 * 60,
    }),
    []
  );
  const [metadata, setMetadata] = useState(defaultMetadata);

  // const dispatch = useAppDispatch()
  // const replayState = useAppSelector(state => state.replay)
  // const startDate = DateTime.fromMillis(replayState.startDate)
  // const endDate = DateTime.fromMillis(startDate.toMillis() + metadata.duration)
  const [startDate, setStartDate] = useState(
    DateTime.now().minus({ minute: 1 }).set({ millisecond: 0, second: 0 })
  );
  const [endDate, setEndDate] = useState(
    startDate.plus({ millisecond: metadata.duration })
  );
  useEffect(() => {
    setEndDate(startDate.plus({ millisecond: metadata.duration }));
  }, [startDate, metadata.duration]);

  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState({ info: false, booking: false });

  const [replayInfo, setReplayInfo] = useState({
    start: 0,
    end: 0,
    counts: {},
  });
  const loadReplay = (start, end) => {
    setIsLoading((state) => ({ ...state, info: true }));
    getReplayInfo(start, end)
      .then((res) => setReplayInfo(res.data))
      .catch((error) =>
        setErrorMessage(error.response.statusText + ": " + error.response.data)
      );
  };

  const saveReplay = () => {
    setIsLoading((state) => ({ ...state, info: true }));
    createReplay(
      metadata.name,
      startDate,
      endDate,
      metadata.createdBy,
      metadata.notes
    )
      .finally(() => setIsLoading((prev) => ({ ...prev, info: false })))
      .catch(console.error);
  };

  const [replayQueries, setReplayQueries] = useState([]);
  // const fetchBookingReplayQueries = (start, end) => {
  //     // setReplayQueries([])
  //     setIsLoading(state => ({...state, booking: true}))
  //     if (replayQueries.length === 0) {
  //         getBookingQueries(start, end).then(res => setReplayQueries(res.data)).catch(error => setErrorMessage(error.response.data))
  //     } else {
  //         setReplayQueries([])
  //     }
  // }
  //
  // const [barrierRequestQueries, setBarrierRequestQueries] = useState([])
  // const fetchBarrierRequestReplayQueries = (start, end) => {
  //     // setReplayQueries([])
  //     setIsLoading(state => ({...state, barrierrequest: true}))
  //     if (replayQueries.length === 0) {
  //         getBarrierRequestQueries(start, end).then(res => setReplayQueries(res.data)).catch(error => setErrorMessage(error.response.data))
  //     } else {
  //         setReplayQueries([])
  //     }
  // }

  useEffect(() => {
    setIsLoading({ info: false, booking: false });
  }, [replayInfo, replayQueries]);

  // if (endDate > startDate.plus({minute: 30})) {
  //     console.warn("end date too far in the future")
  // }
  const [durationClicks, setDurationClicks] = useState(0);
  const maxLength = useMemo(
    () => (durationClicks >= 4 ? 99999 : 30),
    [durationClicks]
  );

  const isDataCurrent = useMemo(
    () =>
      replayInfo.end === endDate.toMillis() &&
      replayInfo.start === startDate.toMillis(),
    [replayInfo, startDate, endDate]
  );
  // console.log(replayInfo.start, startDate.toMillis())
  // console.log(replayInfo.end, endDate.toMillis())
  // console.log(endDate.toMillis())
  // console.log(isDataCurrent)

  const maxEndDate = useMemo(
    () => startDate.plus({ minute: maxLength }),
    [startDate, maxLength]
  );
  const allowSubmit = useMemo(
    () =>
      Object.values(metadata).every((value) => `${value}`.length > 0) &&
      endDate > startDate &&
      endDate <= maxEndDate,
    [metadata, startDate, maxEndDate, endDate]
  );

  const replayLoaded = useMemo(() => replayInfo.end !== 0, [replayInfo]);
  const allowSave = useMemo(
    () => replayLoaded && isDataCurrent,
    [replayLoaded, isDataCurrent]
  );

  const doButtonClick = useCallback(() => {
    if (allowSave) {
      saveReplay();
    } else {
      loadReplay(startDate, endDate);
    }
  }, [metadata, allowSave, startDate, endDate]);
  const doClose = () => {
    if (props.doClose) props.doClose();
  };

  const permissions = useAppSelector((state) => state.permissions);

  return (
    <Paper sx={{ paddingTop: 0.6 }}>
      <Grid container alignItems={"center"}>
        {props.doClose && (
          <IconButton
            sx={{ position: "fixed" }}
            color={"primary"}
            onClick={() => doClose()}
          >
            <ArrowBack />
          </IconButton>
        )}
        <Typography
          sx={{ margin: "auto" }}
          fontWeight={"bold"}
          fontSize={"large"}
          onClick={() => {
            if (permissions.textid === "sysAdmin") {
              setDurationClicks((prev) => prev + 1);
            }
          }}
        >
          Create New Replay
        </Typography>
      </Grid>
      <Box sx={{ padding: 1.2 }}>
        <Grid container>
          <Grid
            xs={12}
            md={6}
            container
            item
            height={"100%"}
            spacing={1}
            flexDirection={"column"}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Box sx={{ display: "block" }}>
              <ControlledTextbox
                value={metadata.name}
                updateValue={(name, value) =>
                  setMetadata((prevState) => ({ ...prevState, name: value }))
                }
                name={"name"}
              />
              <FormItem>
                <FormControl fullWidth>
                  <DateTimePicker
                    date={startDate}
                    setDate={(newDate: DateTime) =>
                      setStartDate((oldDate) => newDate ?? oldDate)
                    }
                    name={"start"}
                    label={"Start Date"}
                  />
                </FormControl>
              </FormItem>
              <FormItem>
                <FormControl fullWidth>
                  <Grid container spacing={2} alignItems="center">
                    <Grid item xs>
                      <FormLabel>Duration (minutes)</FormLabel>
                      <Slider
                        size={"small"}
                        min={1}
                        max={30}
                        step={0.25}
                        valueLabelFormat={(value) => `${value.toFixed(2)} min`}
                        valueLabelDisplay={"auto"}
                        value={metadata.duration / (1000 * 60)}
                        onChange={(event, value) =>
                          setMetadata((prevState) => ({
                            ...prevState,
                            duration: parseFloat(`${value}`) * 1000 * 60,
                          }))
                        }
                      />
                    </Grid>
                    <Grid xs={3.25} item>
                      <Input
                        value={metadata.duration / (1000 * 60)}
                        size="small"
                        onChange={(event) => {
                          const newDuration = parseFloat(
                            `${event.target.value}`
                          );
                          console.log(newDuration);
                          if (!`${newDuration}`.includes("N")) {
                            setMetadata((prevState) => ({
                              ...prevState,
                              duration: newDuration * 1000 * 60,
                            }));
                          }
                        }}
                        inputProps={{
                          step: 0.25,
                          min: 1,
                          max: maxLength,
                          type: "number",
                          "aria-labelledby": "input-slider",
                        }}
                      />
                    </Grid>
                  </Grid>
                </FormControl>
              </FormItem>
              <FormItem>
                <FormControl fullWidth>
                  <DateTimePicker
                    disabled
                    date={DateTime.fromMillis(
                      startDate.toMillis() + metadata.duration
                    )}
                    setDate={(newDate: DateTime) => {
                      if (newDate > maxEndDate) {
                        setEndDate(maxEndDate);
                      } else if (newDate < startDate) {
                        setEndDate(startDate.plus({ minute: 1 }));
                      } else {
                        setEndDate(newDate);
                      }
                    }}
                    name={"end"}
                    label={"End Date"}
                    minDateTime={startDate}
                    maxDateTime={maxEndDate}
                  />
                </FormControl>
              </FormItem>
              <ControlledTextbox
                value={metadata.createdBy}
                updateValue={(name, val) =>
                  setMetadata((prev) => ({ ...prev, createdBy: val }))
                }
                name={"Created By"}
              />
              <FormItem>
                <TextField
                  label={"Notes"}
                  multiline={true}
                  maxRows={3}
                  minRows={1}
                  fullWidth
                  size={"small"}
                  name={"notes"}
                  value={metadata.notes}
                  onChange={(e) =>
                    setMetadata((prev) => ({ ...prev, notes: e.target.value }))
                  }
                />
              </FormItem>
              {/*<ControlledTextbox value={metadata.notes} type={''}*/}
              {/*               updateValue={(name, val) => setMetadata(prev => ({...prev, notes: val}))}*/}
              {/*               name={"Notes"}/>*/}
              <FormItem>
                <FormControl fullWidth>
                  <DateTimePicker
                    disabled={true}
                    date={DateTime.now()}
                    setDate={console.log}
                    name={"created_at"}
                    label={"Created At"}
                  />
                </FormControl>
              </FormItem>
              <FormItem>
                <LoadingButton
                  variant={"contained"}
                  loading={isLoading.info}
                  disabled={!allowSubmit}
                  color={allowSave ? "success" : "primary"}
                  onClick={() => doButtonClick()}
                >
                  {allowSave ? "Save" : "Preview"} Replay
                </LoadingButton>
                <Button
                  variant={"outlined"}
                  disabled={!allowSave}
                  onClick={() => {
                    // setMetadata(defaultMetadata)
                    setReplayInfo((prev) => ({
                      ...prev,
                      start: 0,
                      end: 0,
                      counts: {},
                    }));
                  }}
                >
                  Reset
                </Button>
              </FormItem>
            </Box>
            {/*<Grid item>*/}
            {/*    <LoadingButton variant={'outlined'} loading={isLoading.booking}*/}
            {/*                   onClick={() => fetchBookingReplayQueries(startDate, endDate)}>{replayQueries.length > 0 ? "Hide" : "Show"} Booking*/}
            {/*        Replay</LoadingButton>*/}
            {/*</Grid>*/}
            {/*<Grid item>*/}
            {/*    <LoadingButton variant={'outlined'} loading={isLoading.booking}*/}
            {/*                   onClick={() => fetchBarrierRequestReplayQueries(startDate, endDate)}>{replayQueries.length > 0 ? "Hide" : "Show"} Barrier*/}
            {/*        Request</LoadingButton>*/}
            {/*</Grid>*/}
          </Grid>
          {Object.keys(replayInfo.counts).length > 0 && (
            <Grid
              container
              item
              xs={12}
              md={6}
              spacing={1}
              flexDirection={"column"}
              justifyContent={"center"}
              alignItems={"center"}
              justifySelf={"flex-start"}
            >
              <Typography>Summary</Typography>
              <TableContainer>
                <Table size={"small"}>
                  <TableRow>
                    <TableCell>Start</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {DateTime.fromMillis(replayInfo.start).toLocaleString({
                        timeStyle: "medium",
                        dateStyle: "short",
                        hourCycle: "h23",
                      })}
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell>End</TableCell>
                    <TableCell sx={{ whiteSpace: "nowrap" }}>
                      {DateTime.fromMillis(replayInfo.end).toLocaleString({
                        timeStyle: "medium",
                        dateStyle: "short",
                        hourCycle: "h23",
                      })}
                    </TableCell>
                  </TableRow>
                  {Object.entries(replayInfo?.counts).map((entry) => (
                    <TableRow key={entry[0]}>
                      <TableCell>{`${entry[0]}`}</TableCell>
                      <TableCell>{`${entry[1]}`}</TableCell>
                    </TableRow>
                  ))}
                </Table>
              </TableContainer>
            </Grid>
          )}
          {/*<Paper sx={{margin: 1}}>*/}
          {/*    {replayQueries.length > 0 && <ReplayQueryTable data={replayQueries}/>}*/}
          {/*</Paper>*/}
          <Snackbar
            open={!!errorMessage}
            autoHideDuration={3000}
            onClose={() => setErrorMessage("")}
          >
            <Alert severity={"error"}>{errorMessage}</Alert>
          </Snackbar>
        </Grid>
      </Box>
    </Paper>
  );
  // return <><DraggableWindow position={{ x: 0, y: 100 }} open={props.open} onClose={() => console.log()} title={'Replay Controls'} >
  //     <p>Placeholder</p>
  // </DraggableWindow></>
}

function ReplayQueryTable(props: { data: { delay: number; query: string }[] }) {
  return (
    <TableContainer>
      <Table size={"small"}>
        <TableHead>
          <TableRow>
            <TableCell>Delay</TableCell>
            <TableCell>Query</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.data.map((value, i) => {
            const duration = Duration.fromMillis(value.delay);
            const hms = duration.toFormat("hh mm ss").split(" ");
            return (
              <TableRow key={"query" + i}>
                <TableCell>
                  {hms[0] + "h" + hms[1] + "m" + hms[2] + "s"}
                </TableCell>
                <TableCell>{value.query}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

async function createReplay(
  name: string,
  start: DateTime,
  end: DateTime,
  createdBy: string,
  notes: string
) {
  const replay: FetchedPlayback = {
    name: name,
    start: start.toMillis(),
    end: end.toMillis(),
    createdBy,
    createdAt: Date.now(),
    notes,
  };
  return await axios.post("/api/replay/create", replay);
}

export async function getReplayInfo(start: DateTime, end: DateTime) {
  return await axios.get("/api/replay/info", {
    params: { start: start.toISO(), end: end.toISO() },
  });
}

async function getBookingQueries(start: DateTime, end: DateTime) {
  return await axios.post("/api/generateReport", {
    start: start.toISO(),
    end: end.toISO(),
    timezone: "",
    reportType: "Booking Table Replay",
  });
}

async function getBarrierRequestQueries(start: DateTime, end: DateTime) {
  return await axios.post("/api/generateReport", {
    start: start.toISO(),
    end: end.toISO(),
    timezone: "",
    reportType: "Barrier Requests Table Replay",
  });
}
